import React, { useState } from 'react';
import './styles.scss';
import PageHeaderContent from '../../components/pageHeaderContent';
import emailjs from '@emailjs/browser';
import { RiContactsFill } from 'react-icons/ri';
import { useRef } from 'react';
import { Animate } from 'react-simple-animate';

const Contact = () => {
    const form = useRef();
    const [statusMessage, setStatusMessage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_jxo6oqq', 'template_2haybok', form.current, 'BETBBi4pTsLXxxsAu')
          .then((result) => {
              console.log(result.text);
              setStatusMessage("Hello from the other side...your email was sent successfully!");
          }, (error) => {
              console.log(error.text);
              setStatusMessage("The email has not been sent due to an error: " + error.text);
          });

          e.target.reset();
      };

    return (
        <section id='contact' className='contact'>
            <PageHeaderContent 
                headerText='Contact me'
                icon={<RiContactsFill />}
                page='contact'
            />
            <div className='contact__content'>
            <Animate
                        play
                        duration={1}
                        delay={0}
                        start={{
                            transform: 'translateX(-800px)',
                            overflow: 'hidden'
                        }}
                        end={{
                            transform: 'translateX(0px)',
                            overflow: 'unset'
                        }}
                        >
                <h3 className='contact__content__header-text'>Let's Talk</h3>
                </Animate>
                {statusMessage !== '' && <h3 className='contact__content__status-message'>{statusMessage}</h3> }
                <Animate
                        play
                        duration={1}
                        delay={0}
                        start={{
                            transform: 'translateX(800px)',
                            overflow: 'hidden'
                        }}
                        end={{
                            transform: 'translateX(0px)',
                            overflow: 'unset'
                        }}
                        >
                <form ref={form} className='contact__content__form' onSubmit={sendEmail}>
                    <div className="contact__content__form__controlsWrapper">
                        <div className='nameWrapper'>
                            <input type='text' name='name' id='inputName' className='inputName' placeholder='Name' required />
                            <label htmlFor='name' className='nameLabel'>Name</label>
                        </div>
                        <div className='emailWrapper'>
                            <input type='email' name='email' id='inputEmail' className='inputEmail' placeholder='Email' required />
                            <label htmlFor="email" className='emailLabel'>Email</label>
                        </div>
                        <div className='descriptionWrapper'>
                            <textarea name='description' 
                                id='inputDescription'
                                className='inputDescription'
                                type='text'
                                rows='5'
                                style={{resize: 'none'}}
                                placeholder='Description'
                                required
                            />
                            <label htmlFor='description' className='descriptionLabel'>Description</label>
                        </div>
                    </div>
                    <input type="submit" className='submit' value='Send!' />
                </form>
                </Animate>
            </div>    
        </section>
    )
};

export default Contact;