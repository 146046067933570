import React from 'react';
import PageHeaderContent from '../../components/pageHeaderContent';
import { personalData } from './utils';
import { BsInfoCircleFill } from 'react-icons/bs';
import { DiReact } from 'react-icons/di';
import { FaSalesforce, FaJava, FaPython } from 'react-icons/fa'
import './styles.scss';
import { Animate } from 'react-simple-animate';

const About = () => {
    return (
        <section id='about' className='about'>
            <PageHeaderContent 
                headerText='About Me'
                icon={<BsInfoCircleFill />}
                page='about-me'
            />

            <div className="about__content">
                <div className="about__content__personalWrapper">
                    <Animate
                        play
                        duration={1}
                        delay={0}
                        start={{
                            transform: 'translateX(-800px)',
                            overflow: 'hidden'
                        }}
                        end={{
                            transform: 'translateX(0px)',
                            overflow: 'unset'
                        }}
                    >
                        <h3 className='developerContent'>Full Stack Developer</h3>
                        <p>
                        I am an enthusiastic and hardworking Full Stack Software Engineer &amp; Salesforce Developer with 4+ years overall experience in the industry looking to work at a company with likeminded colleagues delivering the right solution in a timely manner to stakeholders.
                        <br /> <br />
                        I have experience with frontend and backend web app development with languages including, but not limited to, HTML, CSS/SASS, Bootstrap, JavaScript, PHP, JSP, Java, and Spring.
                        <br /> <br />
                        I also have experience developing on the Salesforce platform from most all forms of declarative development that the Salesforce platform offers, to the programmatic with Apex Classes / Triggers, Visualforce, SOQL as well as experience building Lightning Web Components utilizing the Lightning Design System framework.
                        </p>
                    </Animate>
                    <Animate
                        play
                        duration={1}
                        delay={0}
                        start={{
                            transform: 'translateX(500px)',
                            overflow: 'hidden'
                        }}
                        end={{
                            transform: 'translateX(0px)',
                            overflow: 'unset'
                        }}
                    >
                        <h3 className='personalContent'>Personal Inofrmation</h3>
                        <ul>
                            {
                                personalData.filter((item) => item.visible).map((item, key) => (
                                    <li key={key}>
                                        <span className='title'>{item.label}</span>
                                        <span className='value'>{item.value}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </Animate>
                </div>
                <div className="about__content__serviceWrapper">
                    <Animate
                        play
                        duration={1}
                        delay={0}
                        start={{
                            transform: 'translateX(500px)'
                        }}
                        end={{
                            transform: 'translateX(0px)'
                        }}
                    >
                        <div className="about__content__serviceWrapper__innerContent">
                            <div>
                                <DiReact />
                            </div>
                            <div>
                                <FaPython />
                            </div>
                            <div>
                                <FaJava />
                            </div>
                            <div>
                                <FaSalesforce />
                            </div>
                        </div>
                    </Animate>
                </div>
            </div>
        </section>
    )
};

export default About;