import React from 'react';
import './styles.scss';
import 'react-vertical-timeline-component/style.min.css';
import PageHeaderContent from '../../components/pageHeaderContent';
import { FaBlackTie } from 'react-icons/fa';
import { MdWork, MdBook } from 'react-icons/md'
import { FaCertificate } from 'react-icons/fa'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { data } from './utils';

const Resume = () => {
    return (
        <section id='resume' className='resume'>
            <PageHeaderContent 
                headerText='My Resume'
                icon={<FaBlackTie />}
                page='resume'
            />

            <div className="timeline">
                <div className="timeline__experience">
                    <h3 className='timeline__experience__header-text'>Experience</h3>
                    <VerticalTimeline
                        layout='1-column'
                        lineColor='var(--font-color)'
                    >
                        {
                            data.experience.filter((item) => item.visible).map((item, key) => (
                                <VerticalTimelineElement
                                    key={item.title}
                                    className='timeline__experience__vertical-timeline-component'
                                    contentStyle={{
                                        background: 'none',
                                        color: 'var(--font-secondary-color',
                                        border: '1.5px solid var(--font-color)'
                                    }}
                                    date={item.dates}
                                    icon={<MdWork />}
                                    iconStyle={{
                                        background: 'var(--themed-icon-bg-color)',
                                        color: 'var(--themed-icon-color)'
                                    }}
                                >
                                    <div className="vertical-timeline-element-title-wrapper">
                                        <h3>{item.title}</h3>
                                        <h4>{item.subTitle}</h4>
                                        <h4>{item.type}</h4>
                                    </div>
                                    <p className='vertical-timeline-element-description-wrapper'>{item.description}</p>
                                </VerticalTimelineElement>
                            ))
                        }
                    </VerticalTimeline>
                </div>
                <div className="timeline__education">
                    <h3 className='timeline__education__header-text'>Education</h3>
                    <VerticalTimeline
                        layout='1-column'
                        lineColor='var(--font-color)'
                    >
                        {
                            data.education.filter((item) => item.visible).map((item, key) => (
                                <VerticalTimelineElement
                                    key={item.title}
                                    className='timeline__education__vertical-timeline-component'
                                    contentStyle={{
                                        background: 'none',
                                        color: 'var(--font-secondary-color)',
                                        border: '1.5px solid var(--font-color)'
                                    }}
                                    date={item.dates}
                                    icon={<MdBook />}
                                    iconStyle={{
                                        background: 'var(--themed-icon-bg-color)',
                                        color: 'var(--themed-icon-color)'
                                    }}
                                >
                                    <div className="vertical-timeline-element-title-wrapper">
                                        <h3>{item.title}</h3>
                                        <h4>{item.subTitle}</h4>
                                        <h4>{item.type}</h4>
                                    </div>
                                    <p className='vertical-timeline-element-description-wrapper'>{item.description}</p>
                                </VerticalTimelineElement>
                            ))
                        }
                    </VerticalTimeline>
                </div>
                <div className="timeline__certifications">
                    <h3 className='timeline__certifications__header-text'>Certifications</h3>
                    <VerticalTimeline
                        layout='1-column'
                        lineColor='var(--font-color)'
                    >
                        {
                            data.certifications.filter((item) => item.visible).map((item, key) => (
                                <VerticalTimelineElement
                                    key={item.title}
                                    className='timeline__certifications__vertical-timeline-component'
                                    contentStyle={{
                                        background: 'none',
                                        color: 'var(--font-secondary-color)',
                                        border: '1.5px solid var(--font-color)'
                                    }}
                                    date={item.dates}
                                    icon={<FaCertificate />}
                                    iconStyle={{
                                        background: 'var(--themed-icon-bg-color)',
                                        color: 'var(--themed-icon-color)'
                                    }}
                                >
                                    <div className="vertical-timeline-element-title-wrapper">
                                        <h3>{item.title}</h3>
                                        <h4>{item.subTitle}</h4>
                                        <h4>{item.type}</h4>
                                    </div>
                                    <p className='vertical-timeline-element-description-wrapper'>{item.description}</p>
                                </VerticalTimelineElement>
                            ))
                        }
                    </VerticalTimeline>
                </div>
            </div>
        </section>
    );
};

export default Resume;