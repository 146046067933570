import React from 'react';
import { RiShareBoxLine } from 'react-icons/ri'
import Resume from '../../files/Daniel_Boggs_Resume.pdf'
import './styles.scss';

const pageHeaderContent = (props) => {
    const {headerText, icon, page} = props;

    return (
        <div className='header-wrapper'>
            {/* <h2 className='header-wrapper__text'>{headerText}</h2> */}
            <h2 className='header-wrapper__text'>
                { headerText }
            </h2>
            <span className='header-wrapper__icon'>{icon}</span>
        </div>
    )
}

export default pageHeaderContent;